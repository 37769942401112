.diff-menu-number {
    text-align: center;
    font: normal normal normal 36px/45px Source Sans Pro;
    letter-spacing: -0.72px;
    color: #555555;
    opacity: 1;
    margin-left: 0.4rem;
}

.diff-menu-line-v {
    content:"";
    z-index: -1;
    top: 0;
    bottom: 0;
    border-left: 1px solid #DDDDDD;
}

.diff-help-h3{
    color: #555555;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.34px;
}

.diff-help-p {
    text-align: center;
    font-size: 1rem;
    letter-spacing: -0.34px;
    color: #555555;
    padding-top: 0.5rem
}

.diff-bg-green {
    background-color: #30963f;
    margin-left: 0!important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.diff-menu-v-center {
    display: flex;
    align-items: center;
}

@media(max-width:1023px) {
    .diff-mobile {
        padding: 0rem!important;
    }
}