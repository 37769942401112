.lexireto {
  @apply w-full max-w-full overflow-hidden;

  .container {
    display:flex;
    justify-content:center;
  }

  #hexGrid {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(4,fr);

    margin: 0 auto;
    font-size:.9em;
    list-style-type: none;
    overflow:hidden;

  }

  @media (max-width: 640px) {
    #hexGrid {
      width: 90% !important;
    }
  }

  .hex {
    grid-column-end: span 2;
    position: relative;
    visibility:hidden;
    background-color:solid yellow;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
    cursor: pointer;
  }

  .hex::after{
    content:'';
    display:block;
    padding-bottom: 86.602%;  /* =  100 / tan(60) * 1.5 */
  }
  .hexIn{
    position: absolute;
    width:100%;
    padding-bottom: 115.470%; /* =  width / sin(60) */
    overflow: hidden;
    visibility: hidden;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
    transform: rotate3d(0,0,1,-60deg) skewY(30deg);
  }
  .hexIn * {
    position: absolute;
    visibility: visible;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
  }
  .hexLink {
    @apply flex justify-center items-center w-full h-full;
    text-align: center;
    text-transform: uppercase;
    background-color: #efefef;
    overflow: hidden;
    transform: skewY(-30deg) rotate3d(0,0,1,60deg);
  }

  #center-letter {
    background-color:#f5ce5e;
    color: #ffffff !important;

    &:hover {
      background-color:#f4d987;
    }
  }

  input[type="text"]
  {
    background: transparent;
    border: none;
  }

  /*** HEX CONTENT *************************************************************/

  .hex {
    box-sizing:border-box;
    background-color:transparent;
    font-size: 40px;
    color:#001c4c;
    font: normal normal normal 40px/29px Source Sans Pro SemiBold;

    @media screen and (max-width: 640px) {
      font-size: 30px;
    }
  }

  /*!*** HEX HOVER *****************************************************************!*/
  .hexLink:hover {
    background-color: #2D7BB9;
  }

  .hexLink:hover, .hexLink:focus:hover{
    color: #ffffff;
  }

  #hexGrid{
    grid-template-columns: repeat(6,2fr);
    /*padding-bottom: 5.4%;*/
    padding-bottom: 9.4%;
    margin-bottom:2vh;
  }
  .hex:nth-child(5n+1){ /* first hexagon of odd rows */
    grid-column-start: 2;
  }
  .hex:nth-child(5n+3){ /* first hexagon of even rows */
    grid-column-start: 1;
  }

  /*** INPUT & CURSOR ********************************************************************/

  #testword {

    height:50px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    padding-top:3px;
  }

  #cursor {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 100;
    height:6vw;
    color: rgb(255, 187, 0);

  }
  .cursor {
    position: relative;
    margin: 0 auto;
    margin-bottom:10px;
  }

  /*** TEXT **********************************************************************/
  h1 {
    text-align: center;
    margin-top:4vh;
    margin-bottom:1vhpx;
    font-size: 1.5rem;
    font-family: 'Roboto Slab', serif;
  }

  .scoreText {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight:100;
    align-self:center;
  }

  ul {
    /*display:inline-block;*/
    list-style-type: none;
    text-align: center;
  }

  #discoveredText {
    font-family: 'Open Sans',  sans-serif;
    font-size: 15px;
    align-self:center;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
  }

  .dwords{
    font-weight:100;
    padding:0

  }


  /*** BUTTONS *******************************************************************/
  #shuffle_button {
    padding: 10px 10px;
  }

  .button_container {
    display:-webkit-inline-flex;
    margin-bottom:5vh;
    width: 300px;
    height: 10%;

  }
  .button {
    background-color: white;
    border: 2px solid #e7e7e7;
    margin: 0 auto;
    color: black;
    padding: 0px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: .8em;
    border-radius:30px;
    cursor: pointer;
  }

  .button:hover {
    background-color: #e7e7e7;
    color: black;
    border: 2px solid #e7e7e7;
  }

  /*** NOTIFICATIONS AND SHAKE EFFECT ****************************************************************/

  #too-short{
    display:none;
  }
  #invalid-word{
    display:none;

  }
  #already-found{
    display:none;

  }
  #miss-center{
    display:none;

  }
  #good{
    display:None;
    background-color:white;
    color:black;
    border:1px solid rgb(179, 177, 177);
  }

  #great{
    display:None;
    background-color:white;
    color:black;
    border:1px solid rgb(179, 177, 177);
  }

  #pangram{
    display:None;
    background-color:white;
    color:black;
    border:1px solid rgb(179, 177, 177);
  }

  #amazing{
    display:None;
    background-color:white;
    color:black;
    border:1px solid rgb(179, 177, 177);
  }


  .notifications {
    background-color:black;
    color:white;
    display:flex;
    padding:5px;
    align-items:center;
    justify-content:center;
    border-radius:3px;
    font-family: 'Calibri Light',sans-serif;
    font-size: .6em;
  }



  /*@import url(https://allfont.es/allfont.css?fonts=source-sans-pro-semibold);*/


  .titoltuspalabrascont {
    /*font: normal normal normal 18px/22px Source Sans Pro SemiBold;*/
    font-size: 18px;
    font: normal normal normal 18px/22px Source Sans Pro SemiBold;
    /*font: normal normal normal 18px/22px Source Sans Pro SemiBold;*/

    /*font: Source Sans Pro SemiBold;*/
    /*letter-spacing: 0px;*/
    letter-spacing: -0.36px;
    color: #555555;
    opacity: 1;
    text-align: center;

  }


  .titoltuspalabrascont_empiezan {
    /*font: normal normal normal 18px/22px Source Sans Pro SemiBold;*/
    font-size: 14px;
    /*font: normal normal 900 40px/56px Tiempos Headline SemiBold;*/
    font: normal normal normal 14px/17px Source Sans Pro SemiBold;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;

  }

  .titoltuspalabrascont_empiezan_contador {
    /*font: normal normal normal 18px/22px Source Sans Pro SemiBold;*/
    font-size: 14px;
    /*font: normal normal 900 40px/56px Tiempos Headline SemiBold;*/
    font: normal normal normal 14px/17px Source Sans Pro SemiBold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .lr-container-buttons {
    clear: both;
    text-align: center;
    padding-top: 0px;
  }


  .lexireto-container {
    width: 450px;
  }

  @media screen and (min-width: 765px) {
    .lexireto-container {
      height: 445px;
    }
  }


  @media screen and (max-width: 765px) {
    .lexireto-container {
      width: 100%;
      height: 95%;
    }
  }

  .lr-modal {
    position: absolute;
    padding: 10px 10px;
    margin: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 1.4em;
    top: 1.7rem;
    width: 11em;
    text-align: center;
    right: 69%;
    margin-right: -5.5em;
    border-radius: 10px;
    font-weight: bold;

  }

  @media screen and (max-width: 765px) {
    .lr-modal {
      top: 0.3rem;
      right: 50%;
    }
  }

  .paraules-verdes {
    text-align: center;
    font: normal normal normal 36px/45px Source Sans Pro;
    letter-spacing: -0.72px;
    color: #39a351;
    opacity: 1;
  }

  #lr-modalheptacrack {
    background-color: #39A351;
  }

  #lr-modalsubirnivel{
    background-color: #F5CE5E;
    color: #000000;
  }

  #lr-modalminimo4letras, #lr-modalfaltaletracentrals{
    background-color: #ff0000;
    color: #ffffff;
  }



  @keyframes blink {
    100%,
    0% {
      fill: #F5CE5E;
    }
    60% {
      fill: #D1D1D1;
    }
  }

  .intermitentes svg path  {
    animation: blink 0.8s infinite;
  }

}

