@charset "UTF-8";

#rootGames .lc-h2,.lc-h3,.lc-p,.lc-li{
    color: #555555;
}

#rootGames .lc-h3{
    font-size: 1.07rem;
    /*margin-top: 5.17rem;*/
}

#rootGames .lc-h2,.lc-li{
    font-size: 1rem; /* 14px / 16px = 0.87rem */

}

#rootGames .lc-container-exercici-separacio .lc-li{
    list-style: none;
    /*margin-left: -30px;*/
    /*display: inline;*/
    /*margin-right: 45px;*/
    /*margin-bottom: 27px;*/
    margin-bottom: 7px;
    margin-left: 0px ;
    margin-right: 0px ;

}
#rootGames .lc-li:last-child {
    margin-right: 0px;
}

#rootGames .lc-p{
    font-size: 0.94rem;
}

#rootGames .lc-container-exercici-separacio{
    max-width: 32rem;
    width: 100%;
    /*padding-left: 60px;*/
    padding-left: 40px;
}

#rootGames .lc-container-exercici-llista{
    font-size: 0.88rem;
    text-transform: uppercase;
    font-weight:400;
    /*margin-top: 1.42rem;*/
}

#rootGames .lc-titol_petit{
    font-size: 0.94rem;
    font-weight: 600;
}

#rootGames .lc-letras-rellenas{
    font-size: 1.28rem;
    font-weight: 600;
}

#rootGames .lc-remove-btn {
    width: 5px;
    padding-bottom: 3px;
}

/* MOBIL, TABLETS I LAPTOPS */
@media (max-width: 1023px){
    #rootGames .lc-container-exercici-separacio{
        margin-bottom: 2.85rem;
        text-align: center;
        padding-left: 0px;
    }
    #rootGames .lc-ul {
        padding-left: 0px;
    }

    #rootGames .lc-li{
        margin-left: 30px;
        margin-right: 30px;
    }
    #rootGames .lc-li:last-child {
        margin-right: 30px;
    }
}
