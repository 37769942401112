.minicrossword {
  #question-selector {
    width: 450px;

    @media screen and (max-width: 765px) {
      width: 100%;
    }
  }
}

#crossword-grid-container {
  position: relative;
  width: 542px;
  margin: auto;

  @media screen and (max-width: 765px) {
    width: 100%;
  }

  &.minicrossword {

    #grid {

      table {
        margin: auto;
        tr {
          td {
            position: relative;
            @apply align-middle;
            @apply border-disabled-light;
            @apply text-center;
          }


          td {
            @apply border-disabled-dark;
            @apply border;
          }
          td:nth-child(1) {
            @apply border-l-2;
          }
          td:last-child {
            @apply border-r-2;
          }

          &:first-child {
            td {
              @apply border-t-2;
            }
          }

          &:last-child {
            td {
              @apply border-b-2;
            }
          }
        }
      }
    }
  }

  &:not(.minicrossword) {
    #grid {

      table {
        margin: auto;
        tr {
          td {
            position: relative;
            @apply align-middle;
            @apply border-disabled-light;
            @apply text-center;

            &:first-child {
              @apply text-left;
            }
          }


          &:not(:first-child) {
            td {
              @apply border-disabled-dark;
              &:not(:first-child) {
                @apply border;
              }
            }
            td:nth-child(2) {
              &:not(:first-child) {
                @apply border-l-2;
              }
            }
            td:last-child {
              &:not(:first-child) {
                @apply border-r-2;
              }
            }
          }

          &:nth-child(2) {
            td {
              &:not(:first-child) {
                @apply border-t-2;
              }
            }
          }
          &:last-child {
            td {
              &:not(:first-child) {
                @apply border-b-2;
              }
            }
          }
        }
      }
    }
  }
}

.crossword,
.motsencreuats {
  #crossword-grid-container {
    #grid {

      table {
        tr {
          td {
            width: 37px;
            height: 37px;

            @media screen and (max-width: 765px) {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }

}

.minicrossword {
  #crossword-grid-container {
    #grid {

      table {
        tr {
          td {
            width: 90px;
            height: 90px;

            @media screen and (max-width: 765px) {
              width: 70px;
              height: 70px;
            }
          }
        }
      }
    }
  }

}
