#svg-container {
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    width: 100%;

    display: flex;
    flex-direction: row;
}

.tiles-fade-out {
    animation: fadeOut ease 1.3s forwards;
    -webkit-animation: fadeOut ease 1.3s forwards;
    -moz-animation: fadeOut ease 1.3s forwards;
    -o-animation: fadeOut ease 1.3s forwards;
    -ms-animation: fadeOut ease 1.3s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility:hidden;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility:hidden;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility:hidden;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility:hidden;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility:hidden;
    }
}

.tiles-board-wrapper {
    justify-content: center;
}

.tiles-board {
    width: 83%;
    height: 100%;

    display: block;
    transform-origin: top left;
    /* Note: for a CLOCKWISE rotation, use the commented-out
       transform instead of this one. */
    /*transform: rotate(-90deg) translate(-100%);*/

    margin-top: -50%;

    /* Not vital, but possibly a good idea if the element you're rotating contains
       text and you want a single long vertical line of text and the pre-rotation
       width of your element is small enough that the text wraps: */
    white-space: nowrap;
}

.rotation-wrapper-outer {
    width: 100%;
    height: 100%;
    display: table;
}
.rotation-wrapper-inner {
    padding: 85% 0;
}

@media (min-width: 768px) {
    .tiles-board {
        width: 100%;
    }
    .rotation-wrapper-inner {
        padding: 0;
    }
    .tiles-board {
        margin-top: 0px;
    }
}