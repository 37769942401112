.button.group {
  border-radius: 0;
}

.button.group:first-of-type {
  @apply rounded-tl-lg;
  @apply rounded-bl-lg;
}

.button.group:last-of-type {
  @apply rounded-tr-lg;
  @apply rounded-br-lg;
}
