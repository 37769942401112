@charset "UTF-8";

#rootGames {
  .letras {
    table {
      tr {
        td {
          text-align: center!important;
        }
      }
    }
  }
  .lc-title {
      text-transform: capitalize;
  }
  .container-exercici {
      margin-top: 2.78rem;
      margin-bottom: 3.42rem;
  }
  .lc-table-container {
      position: relative;
  }
  .lc-modal{
      display: none;
      position: absolute;
      padding: 10px 10px;
      margin: auto;
      color: #FFFFFF;
      font-size: 1.4em;
      top: 13.5rem;
      width: 11em;
      text-align: center;
      right: 15.71rem;
      margin-right: -5.5em;
      border-radius: 10px;
      font-weight: bold;
  }
  .lc-modal.show{
      display: block;
  }
  #lc-modalOK{
      background-color: #39A351;
  }
  #lc-modalKO, #lc-modalKO2{
      background-color: #ED0B0B;
  }

  /* ESTILS PER MOBIL */
  @media(max-width:1023px){
      .container-exercici {
          margin-top: 0px;
      }

      .lc-centrat{
          margin: auto;
      }

      .container-exercici-espai{
          max-width: 32rem;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
      }
  }

  @media(max-width:502px){
      .lc-modal{
          left: 50%;
      }
  }
}
