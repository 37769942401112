.diff-wrapper {
    width:80%;
    max-width: 80%;
    display:flex;
    flex-wrap:wrap;
}

@media(max-width:1023px) {
    .diff-wrapper {
        width: 100%;
        height: 100%;
        max-width: 100%;
    }
}

.diff-outside-wrapper {
    width:100%;
    height:100%;
    max-width: 100%;
    display:flex;
    flex-wrap:wrap;

}

@media(min-width:767px) {
    .diff-outside-wrapper {
        padding-right: 20px;
    }
}
@media(max-width:767px) {
    div.flex-1:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
        margin-top: 10px;
    }
}

.diff-inside-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.diff-img {
    width: 100%;
    height: 100%;
}

.diff-canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.diff-fade-out {
    animation: diff-fadeOut ease 1s forwards;
    -webkit-animation: diff-fadeOut ease 1s forwards;
    -moz-animation: diff-fadeOut ease 1s forwards;
    -o-animation: diff-fadeOut ease 1s forwards;
    -ms-animation: diff-fadeOut ease 1s forwards;
}

@keyframes diff-fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-moz-keyframes diff-fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes diff-fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-o-keyframes diff-fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-ms-keyframes diff-fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.diff-board-wrapper {
    justify-content: center;
}
