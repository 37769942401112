#rootGames {

  .finished {
    width: 57px;
    height: 57px;
    @apply border-2;
    @apply border-disabled;
  }

  .caption {
    width: 57px;
    height: 57px;

    svg {
      @apply object-contain w-full h-full;
    }
  }

  .slick-slider {
    .slick-arrow {
      @apply border-none;

      &:before {
        display: none;
      }
    }
  }

  .slick-slide-mobile {
    & > div:not(:first-child) {
      .item {
        @apply relative flex items-center justify-center;
        .divider {
          @apply bg-disabled-lighter w-px h-16;
        }
      }
    }
  }
  .slick-slide {

    &:not(:first-child) {

      .item {
        @apply relative flex items-center justify-center;

        .divider {
          @apply bg-disabled-lighter w-px h-16;
        }
      }

    }
  }
}
