.questions {
  ol {
    counter-reset: ordererlist;

    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;

      &:before {
        position: absolute;
        width: 30px;
        text-align: right;
        margin-left: -40px;
        text-align: right;
        counter-increment: ordererlist;
        content: counter(ordererlist) 'H';
      }
    }
  }

  &.vertical {
    ol {
      li {
        &:before {
          content: counter(ordererlist) 'V';
        }
      }
    }
  }
}

.minicrossword .questions {
    @apply justify-start mr-10;

    ol {

      li {
        &:before {
          content: attr(data-question) 'H';
        }
      }
    }

    &.vertical {
      ol {
        li {
          &:before {
            content: attr(data-question) 'V';
          }
        }
      }
    }
  }

