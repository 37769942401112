#grid-container {

  &.sudoku_mini-sudoku {
    table {
      grid-template-rows: repeat(6, 6fr);

      tr {
        &:nth-child(3n):not(:last-child) {
          border-bottom: 2px solid #000;
        }

        td:nth-child(2n):not(:last-child) {
          border-right: 2px solid #000;
        }
      }
    }

  }

  &.sudoku_sudoku-facil,
  &.sudoku_sudoku-medio,
  &.sudoku_sudoku-avanzado, {
    table {
      grid-template-rows: repeat(9, 9fr);

      tr {
        &:nth-child(3n):not(:last-child) {
          border-bottom: 2px solid #000;
        }

        td:nth-child(3n):not(:last-child) {
          border-right: 2px solid #000;
        }
      }

    }
  }

}

