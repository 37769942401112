.tiles-success-modal{
    position: absolute;
    padding: 10px 10px;
    color: #FFFFFF;
    font-size: 1.4em;
    top: 13.5rem;
    width: 11em;
    text-align: center;
    /*right: 15.71rem;*/
    /*margin: auto -5.5em auto auto;*/
    border-radius: 10px;
    font-weight: bold;
}

.tiles-success-modal-ok{
    background-color: #3EA254;
}
.tiles-success-modal-ko{
    background-color: #EA131E;
}

