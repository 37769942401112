@charset "UTF-8";

#rootGames .lc-table-principal{
    background-color: #F5F5F5;
    margin-top: 1.42rem;
    text-align: center;
    max-width: 32rem;
    width: 100%;
    height: 25.14rem;
    font-size: 3.57rem;
    border: solid white;
    border-collapse: collapse;
    color: #000000;
    font-weight: 600;
    float: right;
}

#rootGames .lc-table-principal td {
    border: 1px solid #D1D1D1;
    padding: 6px 1px;
    width: 16.6666%;
    vertical-align: middle;
}
#rootGames .lc-lc-table-prova-disabled {
    border: 3px dashed #F5F5F5;
}
#rootGames .lc-table-principal td.lc-table-prova {
    color: #2D7BB9;
    background-color: #E1EEF8;
    border: 3px solid #2D7BB9;
    padding: 3.5px 0px;
}
#rootGames .lc-table-principal.resultOK td.lc-table-prova {
    color: #39A351;
    border: 3px solid #39A351;
}
#rootGames .lc-table-principal.resultKO td.lc-table-prova {
    color: #ED0B0B;
    border: 3px solid #ED0B0B;
}

#rootGames .lc-grid-text {
    font-size: 0.875em;
}


#rootGames .taulaparaulestrobades{
    /*background-color: #F5F5F5;*/
    /*margin-top: 1.42rem;*/
    text-align: center;
    /*max-width: 32rem;*/
    /*width: 100%;*/
    /*height: 25.14rem;*/
    /*font-size: 3.57rem;*/
    /*border: 1px solid #D1D1D1;*/
    /*border-collapse: collapse;*/
    color: #000000;
    /*font-weight: 600;*/
    /*float: right;*/
    border-collapse: separate;
    /*border-spacing: 0px 4px;*/
    font: normal normal 600 18px/7px Source Sans Pro;
}

#rootGames .lletrestrobades {
    border:  1px solid #D1D1D1;
    width: 32px;
    height: 32px;
    /*width: 12%;*/
    /*background: #F5F5F5 0% 0% no-repeat padding-box;*/
    text-align: center;
    /*padding: 5px;*/
    font-size: 18px;
    font: Source Sans Pro;
    color: #ffffff;
    letter-spacing: -0.36px;
    /*background: #39A351 0% 0% no-repeat padding-box;*/
    /*background: #39A351;*/
    background: #39A351 0% 0% no-repeat padding-box;
    vertical-align: middle;
}


#rootGames .lletrestrobades-notrobades {
    border:  1px solid #D1D1D1;
    width: 32px;
    height: 32px;
    /*width: 12%;*/
    /*background: #F5F5F5 0% 0% no-repeat padding-box;*/
    text-align: center;
    /*padding: 5px;*/
    font-size: 18px;
    font: Source Sans Pro;
    color: #ffffff;
    letter-spacing: -0.36px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    vertical-align: middle;
}

/*table.taulaparaulestrobades tbody tr td.lletrestrobades:td:nth-child(2) {*/
/*    background: #39A351;*/
/*}*/

#rootGames .lletrestrobades_icona {
    border:  1px solid #39A351;
    width: 32px;
    height: 32px;
    /*width: 12%;*/
    /*background: #F5F5F5 0% 0% no-repeat padding-box;*/
    text-align: center;
    /*padding: 5px;*/
    font-size: 18px;
    font: Source Sans Pro;
    color: #ffffff;
    letter-spacing: -0.36px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: #39A351 0% 0% no-repeat padding-box;*/

}
#rootGames .lletrestrobades_icona_noactiva {
    border:  1px solid #D1D1D1;
    width: 32px;
    height: 32px;
    text-align: center;
    /*padding: 5px;*/
    font-size: 18px;
    font: Source Sans Pro;
    color: #ffffff;
    letter-spacing: -0.36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
