.tiles-pairs {
    text-align: center;
    font: normal normal normal 36px/45px Source Sans Pro;
    letter-spacing: -0.72px;
    color: #555555;
    opacity: 1;
}

.tiles-pairs-vertical {
    content:"";
    z-index: -1;
    top: 0;
    bottom: 0;
    border-left: 1px solid #DDDDDD;
}

.help-h3{
    color: #555555;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.34px;
}

.help-p {
    text-align: center;
    font-size: 1rem;
    letter-spacing: -0.34px;
    color: #555555;
    padding-top: 0.5rem
}
