.diff-modal-success {
    position: absolute;
    padding: 0.4rem 0.8rem;
    color: #FFFFFF;
    font-size: 0.9em;
    top: 13.5rem;
    text-align: center;
    /*right: 15.71rem;*/
    /*margin: auto -5.5em auto auto;*/
    border-radius: 10px;
    font-weight: bold;
    background-color: #3EA254;
}