/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: transparent;
}

#rootGames {
  position: relative;
  z-index: 1;
}

@font-face {
  font-family: 'Tiempos Headline Black';
  src: url('./assets/fonts/TiemposHeadline-Black.eot');
  src: url('./assets/fonts/TiemposHeadline-Black.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/TiemposHeadline-Black.woff') format('woff'),
    url('./assets/fonts/TiemposHeadline-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos Headline Medium';
  src: url('./assets/fonts/TiemposHeadline-Medium.eot');
  src: url('./assets/fonts/TiemposHeadline-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/TiemposHeadline-Medium.woff') format('woff'),
    url('./assets/fonts/TiemposHeadline-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url('./assets/fonts/SourceSansPro-Semibold.eot?') format('eot'), url('./assets/fonts/SourceSansPro-Semibold.woff') format('woff'), url('./assets/fonts/SourceSansPro-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times LT Std";
  src: url("./assets/fonts/times-lt-std-extra-bold.eot?"); src: url("./assets/fonts/times-lt-std-extra-bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/times-lt-std-extra-bold.woff2") format("woff2"), url("./assets/fonts/times-lt-std-extra-bold.ttf") format("truetype");
}

main#main-container{position: static;}

:root {
  font-size: 16px;
}
body {
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: #555555;
}

@layer components {
  .menu-ppal {
    & > li {
      @apply flex items-center;
    }
    & > li:not(:first-child):before {
      content: '●';
      font-size: 7px;
    }
  }
}

@layer base {
  #rootGames {
    * {
      @apply border-solid;
    }
    nav {
      @apply h-auto min-h-0 max-h-full;
    }
  }
}

body {
  fill: currentColor!important;

}

svg {
  fill: currentColor!important;
  stroke: currentColor!important;
  font-family: 'Source Sans Pro', sans-serif;

  * {
    font-family: 'Source Sans Pro', sans-serif;
  }

  path:not(.noCurrentFill) {
    fill: currentColor!important;
  }
  path:not(.noCurrentStroke) {
    stroke: currentColor!important;
  }

  .currentFill {
    fill: currentColor!important;
  }

  .currentStroke {
    stroke: currentColor!important;
  }

}

