.diff-modal-error{
    position: absolute;
    color: #FFFFFF;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 95%;
    background-color: #EA131E;
    opacity: .70!important;
}

