@charset "UTF-8";

#rootGames .lc-container-exercici-abaix{
    display:block;
    clear: both;
}

#rootGames .container-exercici-espai .lc-container-buttons {
    clear: both;
    text-align: right ;
    padding-top: 20px;
}

#rootGames .lc-table-comprovacio{
    margin-top: 1.5rem;
    text-align: center;
    font-size: 2rem;
    color: #D1D1D1;
    background-color: #FFFFFF;
    max-width: 32rem;
    width: 100%;
    height: 3.57rem;
    border: 1px solid #D1D1D1;
    float: right;
}
#rootGames .lc-table-comprovacio td{
    border: 1px solid #D1D1D1;
    width: 11.111%;
}
#rootGames .lc-table-comprovacio td.filled{
    color: #2D7BB9;
    background-color: #E1EEF8;
    border: 1px solid #2D7BB9;
}
#rootGames .lc-button{
    background-color: white;
    color: #555555;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 8px 35px;
    margin-left: 5px;
}
#rootGames .lc-button:hover{
    background-color: #2D7BB9;
    color: #FFFFFF;
    border: 1px solid #2D7BB9;
}

@media(max-width:1023px){
    #rootGames .lc-container-exercici-abaix{
        padding-bottom: 40px;
        margin-bottom: 20px;
        border-bottom: 1px solid #CCCCCC;
    }
}
